"use client";
import s from "./not-found.module.css";
import Image from "@/components/Image";
import notFountImage from "@/images/page-error.png";
import Link from "@/components/Link";
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";

const NotFound = ({ error }) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div className={s.notfount}>
      <div className={s.img}>
        <Image fill alt="500" src={notFountImage}></Image>
      </div>
      <div className={s.title}>500</div>
      <div className={s.des}>服务器错误</div>
      <Link className={s.btn} href="/home">
        返回首页
      </Link>
    </div>
  );
};

export default NotFound;
